<template>
  <div>
    <PageLoader />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs6>
            <span class="homeTop">Internship Projects</span>
          </v-flex>
          <v-flex xs6 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="800px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on">
                  <span class="poppinssemibold">
                    Add Project
                  </span>
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addslider" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Project</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout justify-center wrap>
                        <v-flex xs12 pa-2 align-self-center>
                          <v-text-field
                            class="homeText"
                            outlined
                            dense
                            v-model="name"
                            :rules="[rules.required]"
                            required
                            label=" Project Title"
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 pa-2 align-self-center>
                          <v-select
                            class="homeText"
                            :items="bacthlist"
                            label="Select Batch"
                            dense
                            item-text="name"
                            item-value="_id"
                            :rules="[rules.required]"
                            v-model="batch"
                            hide-details
                            outlined
                          ></v-select>
                        </v-flex>

                        <v-flex xs12 pa-2 align-self-center>
                          <v-textarea
                            class="homeText"
                            outlined
                            dense
                            v-model="description"
                            label=" Project Description"
                            hide-details
                          ></v-textarea>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      :disabled="!addslider"
                      text
                      @click="add()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="list.length > 0">
          <v-flex v-for="(item, i) in list" :key="i" md4 pa-4>
            <v-card style="line-height: 16px">
              <v-layout wrap justify-center px-2 py-4>
                <v-flex xs12 pa-2 align-self-center text-center>
                  <span class="poppinssemibold" style="font-size:20px">
                    {{ item.name }} </span
                  ><br /><br />
                </v-flex>
                <v-flex xs12 align-self-center pa-2 text-left v-if="item.batch">
                  <span
                    v-if="item.batch.name"
                    class="poppinsregular"
                    style="font-size:16px"
                    >Batch :
                    <span style="font-size:18px" class="poppinssemibold">
                      {{ item.batch.name }}</span
                    >
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  align-self-center
                  pa-2
                  text-left
                  v-if="item.description"
                >
                  <span class="poppinsregular" style="font-size:16px"
                    >Description :
                    <br />
                    <br />
                    <span style="font-size:18px" class="poppinsregular lheight">
                      {{ item.description.slice(0, 50) }}</span
                    >
                  </span>
                  <br />
                  <br />

                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="item.dialogd"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="text-none"
                        tile
                        color="#000"
                        dark
                        x-small
                      >
                        <span>View more..</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar color="#000" dark
                        ><span class="poppinsregular"
                          >Project Description</span
                        ></v-toolbar
                      >
                      <span class="pa-12">
                        <v-spacer></v-spacer>
                      </span>
                      <v-card-text>
                        <span
                          style="font-size:18px"
                          class=" poppinsregular pa-12"
                        >
                          {{ item.description }}
                        </span>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="item.dialogd = false">Close</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinssemibold"
                        class="mr-2"
                         block
                        icon
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="error"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #000"
                          text-left
                        >
                          <span class="poppinssemibold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this
                        Project?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue"
                          tile
                          text
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn color="error" text tile @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5  text-right justify-center>
                  <v-btn
                    small
                    class="mr-2"
                    title="Edit"
                    block
                    icon
                    depressed
                    color="#000"
                    @click="editProject(item)"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </v-flex>
                <br />
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center wrap pt-5>
          <v-flex xs12 text-center>
            <span class="homeText">No Data Found.....</span>
          </v-flex>
        </v-layout>
        <v-dialog v-model="editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Batch</span>
            </v-card-title>
            <v-card-text>
              <v-layout justify-center wrap>
                <v-flex xs12 pa-2 align-self-center>
                  <v-text-field
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.name"
                    :rules="[rules.required]"
                    required
                    label=" Project Title"
                    hide-details
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 pa-2 align-self-center>
                  <v-select
                    class="homeText"
                    :items="bacthlist"
                    label="Select Batch"
                    dense
                    item-text="name"
                    item-value="_id"
                    :rules="[rules.required]"
                    v-model="editingitem.batch"
                    hide-details
                    outlined
                  ></v-select>
                </v-flex>

                <v-flex xs12 pa-2 align-self-center>
                  <v-textarea
                    class="homeText"
                    outlined
                    dense
                    v-model="editingitem.description"
                    label=" Project Description"
                    hide-details
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editdialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../../store";
export default {
  data() {
    return {
      list: [],
      editingitem: [],
      dialog: false,
      addslider: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      imagePreviewCover: "",
      showPreviewCover: false,
      file1: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
      bacthlist: [],
      batch: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      name: null,
      description: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  beforeMount() {
    this.fromDate = null;
    this.toDate = null;
    this.getBatch();
  },
  mounted() {
    this.getList();
  },
  methods: {
    getBatch() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/internship/batch/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.bacthlist = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/internship/project/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },

    clearOff() {
      this.name = null;
      this.batch = null;
      this.description = null;
    },
    add() {
      if (!this.name) {
        this.msg = "Please provide title";
        this.showSnackBar = true;
        return;
      } else if (!this.batch) {
        this.msg = "Please provide batch";
        this.showSnackBar = true;
        return;
      } else {
        axios({
          method: "post",
          url: "/internship/project/add",
          data: {
            name: this.name,
            batch: this.batch,
            description: this.description,
          },
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              (this.dialog = false), (this.msg = " Added Successully");
              this.showSnackBar = true;
              this.getList();
              this.clearOff();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      }
    },
    edit() {
      this.appLoading = true;
      if (!this.editingitem.name) {
        this.msg = "Please provide title";
        this.showSnackBar = true;
        return;
      } else if (!this.editingitem.batch) {
        this.msg = "Please provide batch";
        this.showSnackBar = true;
        return;
      } else {
        var user = {};
        user["name"] = this.editingitem.name;
        user["batch"] = this.editingitem.batch;
        user["description"] = this.editingitem.description;
        user["id"] = this.editingitem._id;
        axios({
          method: "POST",
          url: "/internship/project/edit",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: user,
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.editdialog = false;
              this.getList();
            } else {
              this.msg = "Can't Update";
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    editProject(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/internship/project/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " "
      //  +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;
      return strTime;
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.texttt {
  line-height: 1.5em;
  min-height: 3.5em;
}
.showLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.v-application .primary {
  background-color: red !important;
}
</style>
